import { TestId } from '@components/test-ids';
import { boxShadowMixins } from '@styles/mixins';
import { Icon } from '../generic-icon/Icon';
import styled from '@emotion/styled';
import { State } from '@model/common';
import { withTheme } from '@emotion/react';
import React, { ReactElement } from 'react';
import { useI18NextContext } from '@components/hooks';
import { ThemeProps } from '@theme/base';
import { TextMarkdown } from '@components/common/description/TextMarkdown';

interface ContainerProps extends ThemeProps {
  state: string;
}

const Container: any = styled.div(({ theme, state }: ContainerProps) => ({
  width: '100%',
  backgroundColor: theme.custom.states[state].light,
  padding: theme.custom.spacing.large,
  ...(theme.custom.typography.paragraph as any),
  display: 'flex',
  alignItems: 'center',
  border: `1px solid ${theme.custom.states[state].dark}`,
  borderRadius: theme.custom.spacing.small,
  boxShadow: boxShadowMixins(theme.custom.colors.group10.base).lighter
}));

const IconContainer = styled.span(({ theme }: ThemeProps) => ({
  marginRight: theme.custom.spacing.large
}));

interface SnackbarIconProps extends ThemeProps {
  state: string;
}

const SnackbarIcon: any = styled(Icon)(({ theme, state }: SnackbarIconProps) => ({
  fontSize: '2rem',
  color: theme.custom.states[state].dark
}));

const SnackbarText = styled.div({
  display: 'block'
});

export interface SnackbarProps {
  text?: string;
  renderText?: ReactElement | undefined;
  state: State;
  icon?: string;
  className?: string;
  testId?: string;
}

export const SnackbarComponent = ({ className, state, icon, text, renderText, testId }: SnackbarProps) => {
  const t: any = useI18NextContext();
  return (
    <Container className={className} state={state} data-testid={testId || TestId.panel}>
      {icon && (
        <IconContainer>
          <SnackbarIcon name={icon} state={state} />
        </IconContainer>
      )}
      <SnackbarText>
        <TextMarkdown description={t(text)} />
      </SnackbarText>
      {renderText}
    </Container>
  );
};

export const Snackbar = withTheme(SnackbarComponent);
