import React, { useState } from 'react';
import { AppVariant } from '@model/config/brands';
import styled from '@emotion/styled';
import { ThemeProps } from '@theme/base';
import { ZIndex } from '@styles/z-index';
import { TestId } from '@components/test-ids';
import { mq } from '@styles/breakpoints';

export interface TalkdeskChatButtonProps {
  talkDeskConfig: {
    devPointId: string;
    prodPointId: string;
    region: string;
  };
  accountId?: string;
  isProd: boolean;
  noTalkDesk?: boolean;
}

declare global {
  interface Window {
    TalkdeskChatSDK?: any;
    webchat?: any;
  }
}

const Container: any = styled.div(({ theme }: ThemeProps) => ({
  position: 'fixed',
  right: theme.custom.spacing.small,
  bottom: 90,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 64,
  height: 64,
  border: 'none',
  borderRadius: '50%',
  cursor: 'pointer',
  padding: 0,
  zIndex: ZIndex.DEV_BANNER,
  transition: `transform 0.2s ${theme.custom.transitions.smooth}`,
  backgroundColor: 'transparent',

  '&:hover': {
    backgroundColor: 'unset',
    cursor: 'pointer'
  },

  '.MuiButton-startIcon': {
    margin: 0
  },

  [mq.medium]: {
    bottom: theme.custom.spacing.large
  }
}));

const Icon = styled.img(() => ({
  width: '100%',
  height: '100%'
}));

export const TalkdeskChatButton = ({ talkDeskConfig, accountId = '', isProd, noTalkDesk }: TalkdeskChatButtonProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const touchpointId = isProd ? talkDeskConfig.prodPointId : talkDeskConfig.devPointId;
  const region = talkDeskConfig.region;
  const shouldShowTalkdesk = !noTalkDesk && !AppVariant.isWhitelabel();

  const pushLog = (message: string) => {
    console.log(`Talkdesk: ${message}`);
  };

  const initializeTalkDesk = () => {
    // If already initialized, just open the chat
    if (window.webchat) {
      window.webchat.open();
      return;
    }

    // If already loading, do nothing
    if (isLoading) return;

    setIsLoading(true);
    pushLog('Initializing Talkdesk Chat');

    // Create container for the chat widget if it doesn't exist
    if (!document.getElementById('tdWebchat')) {
      const divContainer = document.createElement('div');
      divContainer.id = 'tdWebchat';
      document.body.appendChild(divContainer);
    }

    // Check if script is already loaded
    if (document.getElementById('tdwebchatscript')) {
      // Script exists but webchat isn't initialized - re-initialize it
      if (window.TalkdeskChatSDK && !window.webchat) {
        initializeWidget();
      }
      return;
    }

    // Create and load the script
    const script = document.createElement('script');
    const firstScriptTag = document.getElementsByTagName('script')[0];

    script.type = 'text/javascript';
    script.charset = 'UTF-8';
    script.id = 'tdwebchatscript';
    script.src = 'https://talkdeskchatsdk.talkdeskapp.com/v2/talkdeskchatsdk.js';
    script.async = true;

    script.onload = () => {
      pushLog('Talkdesk script loaded');
      initializeWidget();
    };

    script.onerror = () => {
      pushLog('Failed to load Talkdesk script');
      setIsLoading(false);
    };

    firstScriptTag.parentNode?.insertBefore(script, firstScriptTag);
  };

  const initializeWidget = () => {
    setIsLoading(true);

    // Initialize the chat widget using the standard approach
    window.webchat = window.TalkdeskChatSDK('tdWebchat', {
      touchpointId,
      accountId,
      region,
      autoOpen: false
    });

    window.webchat
      .init({
        enableValidation: false,
        enableEmoji: true,
        enableUserInput: true,
        enableAttachments: true,
        enableChatHeader: true,
        styles: {
          // Hide the default button off-screen
          triggerButtonPositionBottom: '-65px',
          triggerButtonPositionRight: '-65px',
          chatPositionBottom: '60px'
        }
      })
      .then(() => {
        pushLog('Chat widget initialized');
        setIsLoading(false);
        window.webchat.open();
      })
      .catch((error: any) => {
        pushLog(`Initialization error: ${error.message || 'Unknown error'}`);
        setIsLoading(false);
      });
  };

  if (!shouldShowTalkdesk) {
    return null;
  }

  return (
    <Container
      data-testid={TestId.talkDeskChat.main}
      ariaLabel="Open chat"
      onClick={initializeTalkDesk}
      disabled={isLoading}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Icon
        src={!isHovered ? '/assets/images/talkdesk/chat_dots_icon.svg' : '/assets/images/talkdesk/chat_smile_icon.svg'}
        alt="Chat Specialists"
        width={'64px'}
        height={'64px'}
      />
    </Container>
  );
};
