import styled from '@emotion/styled';
import { ThemeProps } from '@theme/base';
import { mq } from '@styles/breakpoints';
import { Icon } from '@components/common/generic-icon';
import { Button } from '@components/material-ui';

export const ModalContainer: any = styled.div({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

export const Container: any = styled.div(({ theme }: ThemeProps) => ({
  backgroundColor: theme.custom.colors.white,
  padding: theme.custom.spacing.medium,
  width: '85%',
  borderRadius: 2,
  position: 'relative',
  overflowY: 'auto',
  [mq.small]: {
    padding: theme.custom.spacing.xLarge,
    maxWidth: 600
  }
}));

export const CentredContainer: any = styled(Container)({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column'
});

export const Heading: any = styled.h5(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.h5 as any),
  margin: 0,
  marginBottom: theme.custom.spacing.xLarge,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
}));

export const Paragraph: any = styled.div(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.paragraph as any),
  marginBottom: theme.custom.spacing.large
}));

export const ModalButton: any = styled(Button)(({ theme }: ThemeProps) => ({
  marginTop: theme.custom.spacing.large
}));

export const CloseIcon: any = styled(Icon)(({ theme }: ThemeProps) => ({
  cursor: 'pointer',
  fontSize: '2rem',
  marginLeft: theme.custom.spacing.medium,
  alignSelf: 'flex-start'
}));

export const ModalContent: any = styled.div({});

export const ModalContentText: any = styled.div(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.paragraph as any),
  marginBottom: theme.custom.spacing.xLarge,
  width: '100%'
}));

export const ButtonsContainer: any = styled.div({
  width: '100%',
  [mq.small]: {
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex'
  }
});

export const CTAButton = styled(Button)(({ theme }: ThemeProps) => ({
  whiteSpace: 'pre-line',
  width: '100%',
  marginBottom: theme.custom.spacing.xSmall,

  [mq.small]: {
    width: '48%'
  }
}));
