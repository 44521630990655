import NextHead from 'next/head';
import React, { FC } from 'react';
import { EnvConfig } from '@model/config/env-config';
import uri from 'urijs';
import { useRouter } from 'next/router';
import { removeSearchTokenFromPath } from '@util/path';

export interface HeadProps {
  metaTitle: string;
  metaDescription: string;
  noRobots?: boolean;
  children?: JSX.Element | Array<JSX.Element> | null;
  showShareLinkMeta?: boolean;
  imageUrl?: string;
  brandName?: string;
  url?: string;
  noTalkDesk?: boolean;
}

export const OG_BREAKPOINTS = {
  width: '1200',
  height: '600'
};

export const Head: FC<HeadProps> = ({
  metaDescription,
  metaTitle,
  noRobots,
  children,
  showShareLinkMeta,
  imageUrl,
  brandName,
  url
}: HeadProps) => {
  const showNoRobots: boolean = noRobots || !EnvConfig.isProd();
  const imageType =
    imageUrl && `image/${/\.jpg$/.test(imageUrl) ? 'jpeg' : imageUrl?.substring(imageUrl?.lastIndexOf('.') + 1)}`;

  const renderCanonical = () => {
    const router = useRouter();
    const path: string = router?.asPath || '';
    const withoutQueryParams: string = path.split('?').shift() || '';
    const canonical: string = uri(EnvConfig.getGlobalDomainForEnvironment()).path(withoutQueryParams).href();
    return (
      <>
        <link rel="alternate" href={canonical} hrefLang="en-GB" />
        <link rel="alternate" href={canonical.replace('.co.uk', '.ie')} hrefLang="en-IE" />
        <link rel="alternate" href={canonical} hrefLang="x-default" />
        <link rel="canonical" href={canonical} />
      </>
    );
  };

  return (
    <>
      <NextHead>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        {showNoRobots && <meta name="robots" content="noindex" />}
        {renderCanonical()}
        <meta name="viewport" content="width=device-width, shrink-to-fit=no, initial-scale=1.0" />
        {showShareLinkMeta && (
          <>
            <meta property="og:locale" content="en_GB" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={metaTitle} />
            <meta property="og:description" content={metaDescription} />
            {url && <meta property="og:url" content={removeSearchTokenFromPath(url)} />}
            <meta property="og:site_name" content={brandName} />
            {imageUrl && <meta property="og:image" content={`${imageUrl}?w=${OG_BREAKPOINTS.width}`} />}
            <meta property="og:image:width" content={OG_BREAKPOINTS.width} />
            <meta property="og:image:height" content={OG_BREAKPOINTS.height} />
            {imageType && <meta property="og:image:type" content={imageType} />}
          </>
        )}
        <noscript id="jss-insertion-point" />
        <link
          rel="preload"
          href="/fonts/Mercury-Holidays-Icons/Mercury-Holidays-Icons.woff2"
          as="font"
          crossOrigin=""
        />
        {children}
      </NextHead>
    </>
  );
};

Head.defaultProps = {
  metaDescription: '',
  metaTitle: '',
  noRobots: false
};
