import { useDispatch, useSelector } from 'react-redux';
import { closeModal, ModalType } from '@state/modal/modalOperations';
import React from 'react';
import { BaseModal } from '@components/common/modal';
import { getModalData, getModalType } from '@state/modal/modalSelectors';
import { PromoBannerModal } from './PromoBannerModal';

export const PromoBannerModalModalContainer = () => {
  const dispatch = useDispatch();
  const modalType = useSelector(getModalType);
  const modalData = useSelector(getModalData);
  const handleOnDismiss = () => dispatch(closeModal());

  return (
    <BaseModal
      open={modalType === ModalType.PROMO_BANNER_MODAL}
      onDismiss={handleOnDismiss}
      hideCloseButton
      isScrollBodyModal
    >
      <PromoBannerModal onDismiss={handleOnDismiss} modalData={modalData} />
    </BaseModal>
  );
};
