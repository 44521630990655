import { ModalType } from '@state/modal/modalOperations';
import React from 'react';
import dynamic from 'next/dynamic';

export interface RootModalProps {
  modalType: ModalType;
  props?: any;
}

const DynamicAPISFormErrorContainer = dynamic(
  () => import('../form-error/apis-form-error/APISFormErrorContainer').then((e) => e.APISFormErrorContainer),
  {
    ssr: false
  }
);
const DynamicBasketErrorModalContainer = dynamic(
  () => import('../basket/error/BasketErrorModalContainer').then((e) => e.BasketErrorModalContainer),
  {
    ssr: false
  }
);
const DynamicDealFinderExpirationModalContainer = dynamic(
  () =>
    import('../../deal-finder/results/token/DealFinderExpirationModalContainer').then(
      (e) => e.DealFinderExpirationModalContainer
    ),
  {
    ssr: false
  }
);
const DynamicFormSubmitSuccessModalContainer = dynamic(
  () =>
    import('../form-submit-status/success/FormSubmitSuccessModalContainer').then(
      (e) => e.FormSubmitSuccessModalContainer
    ),
  {
    ssr: false
  }
);
const DynamicFormSubmitErrorModalContainer = dynamic(
  () =>
    import('../form-submit-status/error/FormSubmitErrorModalContainer').then((e) => e.FormSubmitErrorModalContainer),
  {
    ssr: false
  }
);
const DynamicSearchFormGuestsModalContainer = dynamic(
  () => import('../search-form-guests/SearchFormGuestsModalContainer').then((e) => e.SearchFormGuestsModalContainer),
  {
    ssr: false
  }
);
const DynamicTransferLuggageModalContainer = dynamic(
  () => import('../review/TransferLuggageModalContainer').then((e) => e.TransferLuggageModalContainer),
  {
    ssr: false
  }
);
const DynamicGuestPageFormErrorContainer = dynamic(
  () =>
    import('../form-error/guest-page-form-error/GuestPageFormErrorContainer').then(
      (e) => e.GuestPageFormErrorContainer
    ),
  {
    ssr: false
  }
);
const DynamicPaymentPageErrorFormContainer = dynamic(
  () =>
    import('../form-error/payment-page-form-error/PaymentPageFormErrorContainer').then(
      (e) => e.PaymentPageErrorFormContainer
    ),
  {
    ssr: false
  }
);
const DynamicPaymentPageAddressFormErrorContainer = dynamic(
  () =>
    import('../form-error/payment-page-address-form-error/PaymentPageAddressFormErrorContainer').then(
      (e) => e.PaymentPageAddressFormErrorContainer
    ),
  {
    ssr: false
  }
);
const DynamicPromoBannerModalModalContainer = dynamic(
  () => import('../promo-banner').then((e) => e.PromoBannerModalModalContainer),
  {
    ssr: false
  }
);
const DynamicConfirmationModalContainer = dynamic(
  () => import('../confirmation-modal').then((e) => e.ConfirmationModalContainer),
  {
    ssr: false
  }
);
const DynamicContactFormErrorContainer = dynamic(
  () => import('../form-error/contact-form-error/ContactFormErrorContainer').then((e) => e.ContactFormErrorContainer),
  {
    ssr: false
  }
);
const DynamicValidationErrorModalContainer = dynamic(
  () => import('../form-error/validation-error').then((e) => e.ValidationErrorModalContainer),
  {
    ssr: false
  }
);
const DynamicUnmatchedChildAgeModalContainer = dynamic(
  () => import('../unmatched-child-age/UnmatchedChildAgeModalContainer').then((e) => e.UnmatchedChildAgeModalContainer),
  {
    ssr: false
  }
);
const DynamicMyBookingLoginFormErrorContainer = dynamic(
  () => import('../form-error/mmb-login-form-error').then((e) => e.MyBookingLoginFormErrorContainer),
  {
    ssr: false
  }
);
const DynamicTravelDisclaimerFormErrorContainer = dynamic(
  () => import('../form-error/travel-disclaimer-form-error').then((e) => e.TravelDisclaimerFormErrorContainer),
  {
    ssr: false
  }
);
const DynamicPrivateTourEnquiryModalModalContainer = dynamic(
  () =>
    import('../private-tours/PrivateTourEnquiryModalContainer').then((e) => e.PrivateTourEnquiryModalModalContainer),
  {
    ssr: false
  }
);
const DynamicViewRoomTypesModalContainer = dynamic(
  () => import('../product').then((e) => e.ViewRoomTypesModalContainer),
  {
    ssr: false
  }
);
const DynamicSearchErrorModalContainer = dynamic(
  () => import('../search/error/SearchErrorModalContainer').then((e) => e.SearchErrorModalContainer),
  {
    ssr: false
  }
);
const DynamicMyBookingDomainErrorContainer = dynamic(
  () => import('../mmb/MyBookingDomainErrorContainer').then((e) => e.MyBookingDomainErrorContainer),
  {
    ssr: false
  }
);
const DynamicGenericErrorModalContainer = dynamic(
  () => import('../error/GenericErrorModalContainer').then((e) => e.GenericErrorModalContainer),
  {
    ssr: false
  }
);
const DynamicToursErrorModalContainer = dynamic(
  () => import('../tours/error/ToursErrorModalContainer').then((e) => e.ToursErrorModalContainer),
  {
    ssr: false
  }
);
const DynamicEarlyFlightsModalContainer = dynamic(
  () => import('../early-flights').then((e) => e.EarlyFlightsModalContainer),
  {
    ssr: false
  }
);
const DynamicRyanairConfirmFlightsModalContainer = dynamic(
  () => import('../ryanair-flights-confirm').then((e) => e.RyanairConfirmFlightsModalContainer),
  {
    ssr: false
  }
);
const DynamicExitSeatSelectionModalContainer = dynamic(
  () => import('../exit-seat-selection').then((e) => e.ExitSeatSelectionModalContainer),
  {
    ssr: false
  }
);

const modalMapping = {
  [ModalType.BASKET_ERROR]: DynamicBasketErrorModalContainer,
  [ModalType.DEAL_FINDER_TOKEN_EXPIRED]: DynamicDealFinderExpirationModalContainer,
  [ModalType.FORM_SUBMIT_SUCCESS]: DynamicFormSubmitSuccessModalContainer,
  [ModalType.FORM_SUBMIT_ERROR]: DynamicFormSubmitErrorModalContainer,
  [ModalType.SEARCH_GUEST_FORM_ERROR]: DynamicSearchFormGuestsModalContainer,
  [ModalType.TRANSFER_LUGGAGE_MODAL]: DynamicTransferLuggageModalContainer,
  [ModalType.GUEST_PAGE_FORM_ERROR]: DynamicGuestPageFormErrorContainer,
  [ModalType.PAYMENT_PAGE_FORM_ERROR]: DynamicPaymentPageErrorFormContainer,
  [ModalType.PAYMENT_PAGE_ADDRESS_FORM_ERROR]: DynamicPaymentPageAddressFormErrorContainer,
  [ModalType.PROMO_BANNER_MODAL]: DynamicPromoBannerModalModalContainer,
  [ModalType.CONFIRMATION_MODAL]: DynamicConfirmationModalContainer,
  [ModalType.CONTACT_FORM_ERROR]: DynamicContactFormErrorContainer,
  [ModalType.VALIDATION_ERROR]: DynamicValidationErrorModalContainer,
  [ModalType.UNMATCHED_CHILD_AGE]: DynamicUnmatchedChildAgeModalContainer,
  [ModalType.MMB_LOGIN_FORM_ERROR]: DynamicMyBookingLoginFormErrorContainer,
  [ModalType.TRAVEL_DISCLAIMER_FORM_ERROR]: DynamicTravelDisclaimerFormErrorContainer,
  [ModalType.PRIVATE_TOUR_ENQUIRY]: DynamicPrivateTourEnquiryModalModalContainer,
  [ModalType.APIS_FORM_ERROR]: DynamicAPISFormErrorContainer,
  [ModalType.VIEW_ROOM_TYPES_MODAL]: DynamicViewRoomTypesModalContainer,
  [ModalType.SEARCH_ERROR]: DynamicSearchErrorModalContainer,
  [ModalType.MMB_BOOKING_DOMAIN_ERROR]: DynamicMyBookingDomainErrorContainer,
  [ModalType.GENERIC_ERROR]: DynamicGenericErrorModalContainer,
  [ModalType.TOURS_ERROR]: DynamicToursErrorModalContainer,
  [ModalType.EARLY_FLIGHTS_MODAL]: DynamicEarlyFlightsModalContainer,
  [ModalType.RYANAIR_CONFIRM_FLIGHTS_MODAL]: DynamicRyanairConfirmFlightsModalContainer,
  [ModalType.EXIT_SEAT_SELECTION]: DynamicExitSeatSelectionModalContainer
};

export const RootModal = ({ modalType, props }: RootModalProps) => {
  if (modalType === ModalType.NONE) return null;
  const Modal = modalMapping[modalType];
  if (!Modal) return null;
  return <Modal {...props} />;
};
