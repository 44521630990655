import { EndPoint, getURL, Request } from '@model/service';
import uri from 'urijs';
import { CustomerEndpoints } from '@model/iceberg/service/customer/customer-endpoints';
import { OrderBrochureFormValues } from '@model/forms';
import { PaymentAddress } from '@model/payment';

export interface SubscribeToNewsletterPayload {
  email: string;
  reCaptcha: string;
}

interface StartBookingJourneyPayload {
  searchUuid: string;
}

interface StartMmbPayload {
  bookingReference: number;
}

interface GetPaymentMethodsPayload {
  paymentId: string;
}

interface SendAdditionalDetailsPayload {
  paymentId: string;
  data: any;
}

interface SubmitPaymentPayload {
  paymentId: string;
  returnUrl: string;
  paymentType?: number;
  billingAddress: PaymentAddress;
  cardholderName: string;
  email: string;
  amount: number;
  data: any;
}

interface CarrierSuccessPayload {
  paymentId: string;
}

interface CustomerApiRequests {
  subscribeToNewsletter: (payload: SubscribeToNewsletterPayload) => Promise<any>;
  orderBrochure: (payload: OrderBrochureFormValues) => Promise<any>;
  startBookingJourney: (payload: StartBookingJourneyPayload) => Promise<any>;
  startMmb: (payload: StartMmbPayload) => Promise<any>;
  getPaymentMethods: (payload: GetPaymentMethodsPayload) => Promise<any>;
  submitPayment: (payload: SubmitPaymentPayload) => Promise<any>;
}

export class CustomerApi implements CustomerApiRequests {
  private api: Request = new Request();

  public async subscribeToNewsletter(payload: SubscribeToNewsletterPayload): Promise<any> {
    return await this.api.post(
      uri(getURL()).path(`${EndPoint.CUSTOMER}${CustomerEndpoints.NEWSLETTER_SUBSCRIBE}`).href(),
      payload
    );
  }

  public async orderBrochure(payload: OrderBrochureFormValues): Promise<any> {
    return await this.api.post(
      uri(getURL()).path(`${EndPoint.CUSTOMER}${CustomerEndpoints.ORDER_BROCHURE}`).href(),
      payload
    );
  }

  public async startBookingJourney({ searchUuid }: StartBookingJourneyPayload): Promise<any> {
    return await this.api
      .post(uri(getURL()).path(`${EndPoint.PSP}${EndPoint.CUSTOMER_START}${EndPoint.BOOKING_JOURNEY}`).href(), {
        searchUuid
      })
      .catch(() => {
        return null;
      });
  }

  public async startMmb({ bookingReference }: StartMmbPayload): Promise<any> {
    return await this.api.post(uri(getURL()).path(`${EndPoint.PSP}${EndPoint.CUSTOMER_START}${EndPoint.MMB}`).href(), {
      bookingReference
    });
  }

  public async getPaymentMethods({ paymentId }: GetPaymentMethodsPayload): Promise<any> {
    return await this.api.get(
      uri(getURL()).path(`${EndPoint.PSP}${EndPoint.PAYMENT_METHODS}`).search({ paymentId }).href()
    );
  }

  public async sendAdditionalDetails({ paymentId, data }: SendAdditionalDetailsPayload): Promise<any> {
    return await this.api.post(uri(getURL()).path(`${EndPoint.PSP}${EndPoint.PAYMENT_DETAILS}/${paymentId}`).href(), {
      data
    });
  }

  public async submitPayment({ paymentId, ...data }: SubmitPaymentPayload): Promise<any> {
    return await this.api
      .post(uri(getURL()).path(`${EndPoint.PSP}${EndPoint.PAYMENT}`.concat('/').concat(paymentId)).href(), {
        ...data
      })
      .catch(() => {
        return null;
      });
  }
  public async carrierSuccess({ paymentId }: CarrierSuccessPayload): Promise<any> {
    return await this.api
      .get(uri(getURL()).path(`${EndPoint.PSP}${EndPoint.CARRIER_SUCCESS}`.concat('/').concat(paymentId)).href())
      .catch(() => {
        return null;
      });
  }
}
