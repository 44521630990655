import Router from 'next/router';
import moment from 'moment/moment';
import { EnvConfig } from '@model/config/env-config';
import Cookies, { Cookie } from '@model/common/cookie/cookie';
import { sentryException } from '@util/sentry';

export class DevProtect {
  initialize(abta?: string): void {
    const isDev = EnvConfig.isDev();

    if (isDev) {
      const cookies = new Cookies();
      const queryMatch = /\bdevallow=true/.test(Router.asPath);
      const cookieValue = cookies.get(Cookie.DEV_ALLOW);

      if (!queryMatch && !cookieValue) {
        const href = window.location.href;
        const redirectStr = href.replace(/\/dev\./, '/www.');
        if (redirectStr !== href) {
          sentryException(`Dev redirect: from ${href} to ${redirectStr}${abta ? `; abta: ${abta}` : ''}`);
          window.location.replace(redirectStr);
        }
      }
      if (queryMatch && !cookieValue) {
        cookies.set({
          id: Cookie.DEV_ALLOW,
          value: 'true',
          options: {
            path: '/',
            expires: moment().add(400, 'days').toDate(),
            sameSite: 'strict',
            secure: true
          }
        });
      }
    }
  }
}
